import { Sorting } from 'ogp/components/views/administration';
import { OgpApiService } from 'ogp/services/ogp-api-service';
import { PrebuiltBlock } from 'redesign/block';
import { ParamsWithPagination } from 'types/Pagination';
import {
  OgpGetCheckoutsArchiveResponse,
  OgpGetCheckoutsResponse,
  OgpGetInboxFiltersParams,
  OgpGetOutboxFiltersParams,
  OgpProjectCreateResponse,
  OgpProjectGetResponse,
  OgpProjectsCreatePayload,
  ProjectGetParams,
  ProjectGetWorkersResponse,
  ProjectsUpdatePayload,
} from './project-service.types';
import { OGPCheckoutInboxFiltersResponse } from './shift-claim-service.types';
import {
  AddPlacementPayload,
  ProjectCancellation,
} from '../../shared/services/project-service.types';

export class ProjectService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public getProjects = async (params: ProjectGetParams) => {
    const response = await this.ogpApiService.get<PrebuiltBlock[]>({
      path: `projects`,
      params,
    });

    return response;
  };

  public getProjectDetail = async (id: string) => {
    const response = await this.ogpApiService.get<OgpProjectGetResponse>({
      path: `projects/${id}`,
    });

    return response;
  };

  public createProject = async (
    body: OgpProjectsCreatePayload
  ): Promise<OgpProjectCreateResponse> => {
    return await this.ogpApiService.create<OgpProjectCreateResponse>({
      path: `projects/`,
      body,
    });
  };

  public updateProject = async (body: ProjectsUpdatePayload) => {
    const res = await this.ogpApiService.update<any>({
      path: `projects/${body.id}`,
      body,
    });
    return res;
  };

  public archiveProject = async (id: string) => {
    const response = await this.ogpApiService.delete<any>({
      path: `projects/${id}`,
    });

    return response;
  };

  public createPlacement = async (body: AddPlacementPayload) => {
    const response = await this.ogpApiService.create<void>({
      path: `projects/${body.projectId}/create-placement`,
      body,
    });

    return response;
  };

  public getCheckouts = async (
    params: ParamsWithPagination<OgpGetInboxFiltersParams> & Sorting
  ) => {
    const response = await this.ogpApiService.get<OgpGetCheckoutsResponse>({
      path: 'projects/all/checkouts',
      params: {
        ...params,
        fromDate: params.fromDate?.toISODate(),
        toDate: params.toDate?.toISODate(),
      },
    });

    return response;
  };

  public getCheckoutsArchive = async (
    params: ParamsWithPagination<OgpGetOutboxFiltersParams> & Sorting
  ) => {
    const response = await this.ogpApiService.get<OgpGetCheckoutsArchiveResponse>({
      path: 'projects/all/checkouts-archive',
      params: {
        ...params,
        toDate: params.toDate?.toFormat('yyyy-MM-dd'),
        fromDate: params.fromDate?.toFormat('yyyy-MM-dd'),
      },
    });

    return response;
  };

  public getProjectCheckoutInboxFilters = async () => {
    const response = await this.ogpApiService.get<OGPCheckoutInboxFiltersResponse>({
      path: 'projects/all/checkouts-filters',
    });

    return response;
  };

  public getCheckoutsExport = async (body: { checkoutIds: string[] }) => {
    const response = await this.ogpApiService.create<Blob>({
      path: 'projects/all/checkouts/export',
      responseType: 'blob',
      body,
    });

    return response;
  };

  public getProjectCancellations = async (projectId: string) => {
    const response = await this.ogpApiService.get<ProjectCancellation[]>({
      path: `projects/${projectId}/cancellations`,
    });

    return response;
  };

  public getProjectWorkers = async () => {
    const response = await this.ogpApiService.get<ProjectGetWorkersResponse>({
      path: 'projects/workers',
    });
    return response;
  };
}
