import AddIcon from '@mui/icons-material/Add';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useParams } from 'react-router-dom';

import { LwButton } from 'redesign/button';
import { Toolbox } from 'redesign/toolbox';
import { invariant } from 'shared/utils/utils';

import { RenderUnsettledUI } from 'shared/components';
import { Box, styled } from '@mui/material';
import { PartnerRelationsTableProvider } from './partner-relations-table-provider';
import { PartnerPage } from '../partner-page';
import { useGetPartner } from '../../partners/hooks/use-get-partner';

export const PartnerRelations = () => {
  const generatePath = useHqRelativePath();
  const { id } = useParams<'id'>();
  invariant(id);
  const partnerQuery = useGetPartner(id);

  if (partnerQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnerQuery} />;
  }

  return (
    <PartnerPage>
      <StyledBox>
        <Toolbox upperDivider={false}>
          <LwButton
            to={generatePath(hqRoutes.PartnerRelationCreate, { id })}
            startIcon={<AddIcon />}
            iconColorMode="fill"
            color="primary"
            disabled={partnerQuery.data.deactivated}
          >
            Voeg een vestiging toe
          </LwButton>
        </Toolbox>
        <PartnerRelationsTableProvider partnerId={id} deactivated={partnerQuery.data.deactivated} />
      </StyledBox>
    </PartnerPage>
  );
};

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
