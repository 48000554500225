import { Box, Grid, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { EmploymentType, FlexWorkerData, Level } from 'types/flexWorkers/FlexWorkerTypes';
import {
  compareDateStrings,
  dateOfBirthValidator,
  validateDateString,
} from 'shared/utils/validate-date';
import { LwFormDate } from 'redesign/forms/date';
import { LwFormInput } from 'redesign/forms/input';
import { FormCheckbox } from 'redesign/forms/checkbox-group';
import { LwFormSelect } from '../../../../../../redesign/forms/select';
import {
  getAvailableLevelUpgrades,
  getNextLevelRequirementsText,
} from '../../../../../../shared/utils/level-utils';
import { IconQuestion } from '../../../../../../assets/img';
import { useFeatureFlagContext } from '../../../../../../shared/contexts/feature-flag-provider';

type FlexWorkerFormEditProps = {
  flexWorkerData: FlexWorkerData;
};

export const FlexWorkerFormEdit = ({ flexWorkerData }: FlexWorkerFormEditProps) => {
  const { control, watch, getValues } = useFormContext<FlexWorkerData>();
  const { allowCandidateOnboarding } = useFeatureFlagContext();

  const BSStartDate = watch('smallBusinessSchemeStartDate');
  const BSEndDate = watch('smallBusinessSchemeEndDate');
  const level = getValues('level');

  const validateBSStartEndDateComparison = () =>
    BSStartDate?.length && BSEndDate?.length && !compareDateStrings(BSStartDate, BSEndDate)
      ? 'De opgegeven einddatum is eerder dan de begindatum'
      : undefined;

  const validateBSStartDateExists = () => {
    const BSStartDateExists = BSStartDate && BSStartDate.length > 0;
    const BSEndDateExists = BSEndDate && BSEndDate.length > 0;
    return !BSStartDateExists && BSEndDateExists ? 'Oops, geen KOR begindatum bekend' : undefined;
  };

  const validateBSDate = (value: string | undefined | null) =>
    value && value.length > 0 && !validateDateString(value)
      ? 'De datum die je invult lijkt niet te kloppen'
      : undefined;

  const validateDateOfBirth = (value: any) =>
    value && value.length > 0 && !dateOfBirthValidator(value)
      ? 'De datum die je invult lijkt niet te kloppen'
      : undefined;

  return (
    <Box>
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={12}>
          <h2 className="subtitle">Persoonsgegevens</h2>
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="firstName"
            label="Voornaam"
            control={control}
            rules={{ required: 'Voer een voornaam in' }}
          />
        </Grid>
        <Grid item xs={2}>
          <LwFormInput name="infix" label="Tussenvoegsel" control={control} />
        </Grid>
        <Grid item xs={4}>
          <LwFormInput
            name="lastName"
            label="Achternaam"
            control={control}
            rules={{ required: 'Voer een achternaam in' }}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="bsn"
            label="BSN"
            control={control}
            inputType="number"
            rules={{
              minLength: { value: 9, message: 'BSN moet 9 cijfers bevatten' },
              maxLength: { value: 9, message: 'BSN moet 9 cijfers bevatten' },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="dateOfBirth"
            label="Geboortedatum"
            helperText="dd-mm-jjjj"
            control={control}
            data-testid="dateOfBirth"
            rules={{
              validate: {
                validateDateOfBirth,
              },
              required: 'Voer een geboortedatum in',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <h2 className="subtitle">Zakelijke gegevens</h2>
        </Grid>
        <Grid item xs={6}>
          <LwFormDate
            name="smallBusinessSchemeStartDate"
            label="KOR Startdatum"
            control={control}
            rules={{
              validate: {
                // @ts-expect-error bad validation typing
                validateBSDate,
                validateBSStartDateExists,
                validateBSStartEndDateComparison,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormDate
            name="smallBusinessSchemeEndDate"
            label="KOR Einddatum"
            rules={{
              validate: {
                // @ts-expect-error bad validation typing
                validateBSDate,
                validateBSStartDateExists,
                validateBSStartEndDateComparison,
              },
            }}
            control={control}
          />
        </Grid>
        {flexWorkerData.employmentType === 'TEMPWORKER' ? (
          <>
            <Grid item xs={12}>
              <h2 className="subtitle">Zakelijke gegevens</h2>
            </Grid>
            <Grid item xs={6}>
              <FormCheckbox label="Loonheffingskorting" control={control} name="wageTaxCredit" />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <h2 className="subtitle">Level aanpassen</h2>
        </Grid>
        <Grid item xs={4}>
          <LwFormSelect
            name="level"
            label="Kies level"
            icon={
              <LevelTooltip
                level={level}
                employmentType={flexWorkerData.employmentType}
                allowCandidateOnboarding={allowCandidateOnboarding}
              />
            }
            control={control}
            options={getAvailableLevelUpgrades(
              flexWorkerData.level,
              flexWorkerData.employmentType,
              allowCandidateOnboarding
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const LevelTooltip = ({
  level,
  employmentType,
  allowCandidateOnboarding,
}: {
  level: Level;
  employmentType: EmploymentType;
  allowCandidateOnboarding: boolean;
}) => {
  return (
    <StyledTooltip
      title={
        <>
          <h3 className="subtitle">Flexwerker Levels</h3>
          <p>{getNextLevelRequirementsText(level, employmentType, allowCandidateOnboarding)}</p>
        </>
      }
    >
      <Box>
        <StyledQuestionIcon />
      </Box>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement="right-start" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.lwBlack[100],
    color: theme.palette.lwWhite[100],
    borderRadius: '10px',
  },
}));

const StyledQuestionIcon = styled(IconQuestion)(({ theme }) => ({
  fill: theme.palette.lwSecondary[40],
  marginLeft: theme.spacing(2),
}));
