import { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteProps,
  PaperProps,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import arrowDown from 'assets/img/icons/redesign/arrow-down.svg';
import { IconClose } from 'assets/img';
import { filledInputClasses } from '@mui/material/FilledInput';
import { StyledLabel } from 'redesign/utils/labels';
import { StyledIconWarning } from 'redesign/utils/helper-text';
import { FormMultiSelectProps } from './form-multi-select.types';

const LwFormMultiSelect = <
  T extends object = object,
  ControlFields extends FieldValues = FieldValues,
>({
  name,
  label,
  options,
  control,
  onChange,
  rules,
  disabled,
  'data-testid': dataTestId,
  ariaLabelInput,
  getOptionLabel,
  isOptionEqualToValue,
  renderOption,
  getOptionKey,
  ...props
}: FormMultiSelectProps<T, ControlFields>) => {
  renderOption =
    renderOption ??
    ((props, option) => (
      <li {...props} key={getOptionKey(option)}>
        {getOptionLabel(option)}
      </li>
    ));

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <StyledAutocomplete
          {...field}
          {...props}
          value={field.value ?? []}
          title={label}
          multiple
          options={options}
          noOptionsText={'Geen resultaten gevonden'}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          isOptionEqualToValue={isOptionEqualToValue}
          filterSelectedOptions
          ChipProps={{ deleteIcon: <StyledCloseIcon /> }}
          disableClearable
          disabled={disabled}
          PaperComponent={(paperProps: PaperProps) => (
            <StyledAutocompletePaper hasError={!!error} {...paperProps} />
          )}
          data-testid={dataTestId}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label={
                <StyledLabel
                  icon={error ? <StyledIconWarning /> : null}
                  required={!!rules?.required}
                >
                  {label}
                </StyledLabel>
              }
              name={name}
              InputLabelProps={{
                shrink: true,
                htmlFor: name,
              }}
              InputProps={{
                ...params.InputProps,
                'aria-label': ariaLabelInput,
              }}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          onChange={(event, values, reason, detail) => {
            field.onChange(values);
            if (onChange) {
              onChange(event, values, reason, detail);
            }
          }}
        />
      )}
    />
  );
};

export const StyledAutocompletePaper = styled('div')<{ hasError?: boolean }>(
  ({ theme, hasError }) => ({
    marginTop: theme.spacing(2),
    boxShadow: hasError
      ? '0px 12px 16px 0px rgba(204, 68, 0, 0.08)'
      : '0px 12px 16px 0px rgba(0, 26, 153, 0.08)',
    borderRadius: 12,
    backgroundColor: theme.palette.lwWhite[100],
    [`& .${autocompleteClasses.listbox}`]: {
      border: hasError
        ? `2px solid ${theme.palette.lwDanger[100]}`
        : `2px solid ${theme.palette.lwSecondary[40]}`,
      borderRadius: 12,
      color: theme.palette.lwBlack[100],
      '::-webkit-scrollbar': {
        width: '20px',
      },
      '::-webkit-scrollbar-track': {
        background: theme.palette.lwSecondary[40],
        border: '8px solid white',
        borderRadius: theme.spacing(4),
      },

      '::-webkit-scrollbar-thumb': {
        background: theme.palette.lwSecondary[100],
        backgroundClip: 'content-box',
        border: '8px solid transparent',
        borderRadius: theme.spacing(4),
      },
      [`& .${autocompleteClasses.option}`]: {
        marginRight: 6,
        marginLeft: 6,
        alignItems: 'center',
        borderRadius: '8px',
        fontWeight: 600,
        fontFamily: 'Inter',
        fontSize: 14,
        '&:hover': {
          color: theme.palette.lwPrimary[100],
        },
        [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
          {
            backgroundColor: theme.palette.lwPrimary[5],
          },
      },
    },
  })
);

export type LwAutocompleteProps<T> = AutocompleteProps<T, true, true, false>;

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .${autocompleteClasses.inputRoot}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    marginTop: 0,
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwSecondary[20]}`,
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${autocompleteClasses.tag}`]: {
    backgroundColor: theme.palette.lwPrimary[10],
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '16px',
    height: '32px',
    gap: '8px',
    borderRadius: theme.spacing(6),
    padding: '6px 12px',
    margin: 0,
  },
  '.MuiChip-label': {
    color: theme.palette.lwPrimary[100],
    padding: 0,
  },
  [`& .${autocompleteClasses.popupIndicator}`]: {
    color: 'transparent',
    background: `url("${arrowDown}") no-repeat center center`,
    '&.Mui-disabled': {
      color: 'transparent',
      background: `url("${arrowDown}") no-repeat center center`,
    },
  },
  '.MuiAutocomplete-inputRoot:has(.MuiChip-root)': {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  [`& .${autocompleteClasses.endAdornment}`]: {
    marginRight: 10,
  },
  '&& .MuiChip-deleteIcon': {
    width: '16px',
    height: '16px',
    margin: 0,
    fill: theme.palette.lwSecondary[100],
  },
  [`& .${formHelperTextClasses.root}`]: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  [`& .${inputBaseClasses.root}`]: {
    display: 'inline-flex',
    alignContent: 'center',
    padding: '16px',
    gap: 8,
  },
  [`.${autocompleteClasses.input}`]: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
  },
})) as typeof Autocomplete;

const StyledCloseIcon = styled(IconClose)(({ theme }) => ({
  fill: theme.palette.lwSecondary[60],
}));

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  [`& .${inputAdornmentClasses.root}`]: {
    marginRight: theme.spacing(20),
  },
}));

const LwMultiSelectUncontrolled = <
  T extends object = object,
  ControlFields extends FieldValues = FieldValues,
>({
  label,
  options,
  onChange,
  rules,
  disabled,
  'data-testid': dataTestId,
  ariaLabelInput,
  value,
  renderOption,
  getOptionKey,
  getOptionLabel,
}: Omit<FormMultiSelectProps<T, ControlFields>, 'control' | 'name'> & {
  value: any[];
}) => {
  return (
    <StyledAutocomplete
      value={value ?? []}
      title={label}
      multiple
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
      renderOption={renderOption}
      ChipProps={{ deleteIcon: <StyledCloseIcon /> }}
      filterSelectedOptions
      disableClearable
      onChange={onChange}
      disabled={disabled}
      PaperComponent={(paperProps: PaperProps) => <StyledAutocompletePaper {...paperProps} />}
      data-testid={dataTestId}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={label}
          InputLabelProps={{
            required: !!rules?.required,
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            'aria-label': ariaLabelInput,
          }}
        />
      )}
    />
  );
};

export { LwMultiSelectUncontrolled, LwFormMultiSelect };
