import { Column } from 'react-table';
import { ContextMenuButton } from 'redesign/context-menu';
import { Cell, Header } from 'redesign/table';
import { UserAvatar } from 'redesign/user-avatar/user-avatar';
import { PlacementCellProps, PlacementColumnDefinition } from './placements-table.types';
import { getFlexWorkerLabel } from '../../../../../../utils/get-flex-worker-label';

export const getPlacementsColumnDefinition = () => {
  const flexWorkerImage: Column<PlacementColumnDefinition> = {
    Header: '',
    accessor: 'flexWorkerAvatar',
    Cell: FlexWorkerImageCell,
  };

  const fw: Column<PlacementColumnDefinition> = {
    Header: () => <Header>Flexwerker</Header>,
    accessor: 'flexWorker',
    Cell: ({ value }) => <Cell>{value}</Cell>,
  };

  const email: Column<PlacementColumnDefinition> = {
    Header: () => <Header>Emailadres</Header>,
    accessor: 'email',
    Cell: ({ value }) => <Cell>{value}</Cell>,
  };

  const phone: Column<PlacementColumnDefinition> = {
    Header: () => <Header>Telefoonnummer</Header>,
    accessor: 'phone',
    Cell: ({ value }) => <Cell>{value}</Cell>,
  };

  const actions: Column<PlacementColumnDefinition> = {
    Header: '',
    accessor: 'actions',
    Cell: ActionCell,
  };

  return [flexWorkerImage, fw, email, phone, actions];
};

const FlexWorkerImageCell = ({ value, onProfileClick }: PlacementCellProps<'flexWorkerAvatar'>) => {
  const { id, firstName, lastName, relation, imageUrl, employmentType } = value;
  const avatarLabel = getFlexWorkerLabel(employmentType);

  return (
    <UserAvatar
      onClick={() => onProfileClick(id, relation)}
      firstName={firstName}
      lastName={lastName}
      imageUrl={imageUrl}
      avatarLabel={avatarLabel}
    />
  );
};

const ActionCell = ({ value, getRowActions }: PlacementCellProps<'actions'>) => {
  const actions = getRowActions(value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`placements-actions-menu-${value.placement.flexWorker.id}`}>
      {actions}
    </ContextMenuButton>
  );
};
